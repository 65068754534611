/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'hdmi-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M1 5a1 1 0 00-1 1v3.293c0 .39.317.707.707.707.188 0 .368.075.5.207l.5.5a1 1 0 00.707.293h11.172a1 1 0 00.707-.293l.5-.5a.7.7 0 01.5-.207c.39 0 .707-.317.707-.707V6a1 1 0 00-1-1zm1.5 2h11a.5.5 0 010 1h-11a.5.5 0 010-1"/>',
    },
});
